import "../../style.css";
import React, { useState, useMemo } from "react";
import EditableField from "../../components/EditableField";
import { BsArrowDownUp } from "react-icons/bs";
//import { Link } from "react-router-dom";

const LocateFetchTable = ({ data, handleCheckboxChange, setFilterData, setData, filterData, selectedIds, selectAll, handleSelectAll }) => {

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const handleEdit = (id, field, value) => {
    const updatedData = filterData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    setData(updatedData);
    setFilterData(updatedData);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = useMemo(() => {
    const sortedArray = [...data];

    if (sortColumn !== "") {
      sortedArray.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "lastmodified") {
          // Convert timestamps to Date objects for comparison
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();

          if (dateA < dateB) return sortDirection === "asc" ? -1 : 1;
          if (dateA > dateB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        } else {
          if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
          if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        }
      });
    }

    return sortedArray;
  }, [data, sortColumn, sortDirection]);


  return (
    <>
      <div className="container-fluid mb-2 mt-2">
        <table id="" className="table table-bordered" style={{ color: "#222" }}>
          <thead className="">
            <tr>
              <th className="width-1p" scope="row">
                <input type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll} />
              </th>
              <th onClick={() => handleSort("id")} className="width-2p" scope="col">
                id{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
              <th className="width-10p" scope="col">
                Source text
              </th>
              <th className="width-7p" scope="col">
                Clean MD5
              </th>
              <th className="width-3p" scope="col">
                multipart
              </th>
              <th
                onClick={() => handleSort("part")}
                className="width-3p"
                scope="col"
              >
                part{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
              <th
                onClick={() => handleSort("seq")}
                className="width-5p"
                scope="col">
                sequence{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
              <th
                onClick={() => handleSort("lastmodified")}
                className="width-6p"
                scope="col"
              >
                Request Date{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((myData) => (
              <tr key={myData.id} style={{ backgroundColor: selectedIds.includes(myData.id) ? '#c1d3f0' : '' }}>
                <td className="">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customCheck${myData.id}`}
                      checked={selectedIds.includes(myData.id) || selectAll}
                      onChange={() => handleCheckboxChange(myData.id)}
                    />
                  </div>
                </td>
                <td className="">{myData.id}</td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  <div> {myData.srctxt} </div>
                </td>
                {myData.scriptpath ?
                  <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                    {myData.cleanmd5}
                  </td>
                  :
                  <td>NA</td>
                }

                <td>
                  <EditableField
                    value={myData.multipart}
                    onChange={(value) =>
                      handleEdit(myData.id, "multipart", value)
                    }
                  />
                </td>
                <td>
                  <EditableField
                    value={myData.part}
                    onChange={(value) => handleEdit(myData.id, "part", value)}
                  />
                </td>
                <td>
                  <div>{myData.seq}</div>
                </td>
                <td>
                  <div>{myData.reqDate}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LocateFetchTable;

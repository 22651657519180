import React, { useState, useEffect } from 'react';
import { GlobalContext } from './GlobalContext';
import axios from "axios";
import ToastNotification from './ToastNotification';

const GlobalProvider = ({ children }) => {
  const [filterData, setFilterData] = useState([]);
  const [DomainData, setDomainData] = useState([]);
  const [filterDomainData, setFilterDomainData] = useState([]);

  const [toast, setToast] = useState(null);

  const showToast = (type, message, position, distance) => {
    setToast({ type, message, position, distance });
    setTimeout(() => {
      setToast(null);
    }, 10000);
  };

  const API_URL = process.env.REACT_APP_API_URL;
    
   useEffect(() => {
    const DomainFetchData = async () => {
      if (window.location.pathname === '/contents') {
      try {
        const response = await axios.get(`${API_URL}/DomainTableData`);
        setDomainData(response.data);
        setFilterDomainData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    };
    DomainFetchData();
    // eslint-disable-next-line
  }, []);

  const contextValue = { API_URL, filterData, setFilterData, DomainData, setDomainData, filterDomainData, setFilterDomainData, showToast };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
      {toast && <ToastNotification type={toast.type} message={toast.message} position={toast.position}
          distance={toast.distance} />}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;

import React, { useState, useRef, useEffect } from "react";

const EditableField = ({ value, onChange }) => {
  const [editing, setEditing] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const textareaRef = useRef(null);

  const handleInputChange = (event) => {
    setUpdatedValue(event.target.value);
  };

  const handleEdit = () => {setEditing(true)};

  const handleSave = () => {
    onChange(updatedValue);
    setEditing(false);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      handleSave();
    } else if (event.key === 'Escape') {
      setEditing(false);
    }
  };

  useEffect(() => {
    if (editing && textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editing, updatedValue]);

  return (
    <div style={{ wordWrap: "break-word", overflow: "hidden" }}>
      {editing ? (
        <textarea
          ref={textareaRef}
          type="text"
          className="form-control"
          value={updatedValue === null ? "-" : updatedValue}
          onChange={handleInputChange}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            width: "100%",
            resize: "none",
            overflow: "hidden",
          }}
        />
      ) : (
        <div
          onClick={handleEdit} 
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="button" 
        >
          {value === null ? "NA" : value}
        </div>
      )}
    
    </div>
  );
};

export default EditableField;

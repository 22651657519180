import React, { useState, useContext } from "react";
import "../../style.css";
import axios from "axios";
//import Pagination from "../../components/Pagination";
import ContentFetchTable from "./ContentFetchTable";
import DomainDropdown from "../../components/DomainDropdown";
import Search from "../../components/Search";
import { GlobalContext } from '../../context/GlobalContext';
import ModelView from "../collection/ModelView"
import Popup from "../popup/Popup";
import PaginationModule from "../../pagination/PaginationModule";

const DomainTable = () => {
  const { API_URL, DomainData, setDomainData, filterDomainData, setFilterDomainData } = useContext(GlobalContext);

  const [showAlert, setShowAlert] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");

  // eslint-disable-next-line
  const [selectAll, setSelectAll] = useState(false);
  const [transferData, setTransferData] = useState("");
  const [message, setMessage] = useState(false);

  // Get current posts
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filterDomainData.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(filterDomainData.length / postsPerPage);


  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const MergedHandle = async () => {
    const selectedData = DomainData.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);
    setMessage(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}/mergedAndTransfer`,
        selectedData
      );
      setTransferData(response.data)
      console.log('message mergedAndTransfer', transferData)

      const resetData = DomainData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      const resetFilterData = filterDomainData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });
      setMessage(false);
      setDomainData(resetData);
      setFilterDomainData(resetFilterData);
      setSelectedIds([]);
      setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  };

  const singleTransfer = async () => {
    const selectedData = DomainData.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);
    setMessage(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}/singleTransfer`,
        selectedData
      );
      setTransferData(response.data)
      console.log('message mergedAndTransfer', transferData)

      const resetData = DomainData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      const resetFilterData = filterDomainData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      setDomainData(resetData);
      setFilterDomainData(resetFilterData);
      setSelectedIds([]);
      setMessage(false);
      setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    const currentPageIds = currentPosts.map(item => item.id);
    const allSelected = currentPageIds.every(id => selectedIds.includes(id));

    if (allSelected) {
      // If all are selected, unselect all
      setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
    } else {
      // If not all are selected, select all
      setSelectedIds([...selectedIds, ...currentPageIds]);
    }
  };


  const handleRemoveText = (indexToRemove) => {
    setSelectedIds((prevSelectedIds) => {
      // Create a new array without the item at the specified index
      const updatedSelectedIds = prevSelectedIds.filter((_, index) => index !== indexToRemove);
      return updatedSelectedIds;
    });
  };

  const checkAll = () => {
    const confirmCheckAll = window.confirm("Are you sure you want to check all data for transfer?");

    if (confirmCheckAll) {
      const currentPageIds = filterDomainData.map(item => item.id);
      const allSelected = currentPageIds.every(id => selectedIds.includes(id));

      if (allSelected) {
        setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
      } else {
        setSelectedIds([...selectedIds, ...currentPageIds]);
      }
    }
  };

  return (
    <>
      <div className="container-fluid mb-2">
        <div className="row justify-content-between">

          <div className="col-md-4 ms-4 px-0 py-0">
            <Search data={filterDomainData} setFilterData={setFilterDomainData} />
          </div>

          <div className="col-md-4 px-0 py-0">
            <DomainDropdown
              data={filterDomainData} // Use the filtered data here
              selectedDomain={selectedDomain}
              setSelectedDomain={setSelectedDomain}
              setFilterData={setFilterDomainData}
            />
          </div>

          <div className="col-md-1 px-0 py-0">
            {selectedDomain ?
              <button className="btn btn-light border" onClick={checkAll}>Check All</button>
              : ""}
          </div>

          <div className="col-md-2">
            {selectedIds.length > 0 && (
              <button className="btn btn-light border text-danger ms-1">Selected: {selectedIds.length}</button>
            )}
          </div>
        </div>
      </div>

      <div className="container ms-3 mb-2">
        <div className="d-flex justify-content-end">
          {selectedIds.length > 0 && (
            <div className="d-flex justify-content-end">
              <button className="btn btn-light border" onClick={MergedHandle}>Merged Transfer</button>
              <button className="btn btn-light border" onClick={singleTransfer}>Single Transfer</button>
              <button className="btn btn-light border" onClick={() => setSelectedIds([])}>Clear</button>
              <ModelView data={DomainData} selectedIds={selectedIds} handleRemoveText={handleRemoveText} />
            </div>
          )}
        </div>
      </div>

      <div className="">
        {message && (
          <Popup setOpenModal={setMessage} message="Data processing, please wait..." />)}
      </div>

      <div>
        {showAlert && (
          <pre className="p-3 mb-2 bg-alert-success text-white" role="alert">
            {transferData.message.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </pre>
        )}
      </div>

      <div className="container-fluid mb-2">
        <ContentFetchTable
          currentPosts={currentPosts}
          handleCheckboxChange={handleCheckboxChange}
          setFilterData={setFilterDomainData}
          filterData={filterDomainData}
          setData={setDomainData}
          selectedIds={selectedIds}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
        />
      </div>

      <div className="">
        <PaginationModule totalPages={howManyPages} postsPerPage={postsPerPage} setPostsPerPage={setPostsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>

    </>
  );
};

export default DomainTable;

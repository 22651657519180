import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalContext';
import LocateFetchTable from './LocateFetchTable';
import Popup from '../popup/Popup';

const Locate = () => {
  const { API_URL } = useContext(GlobalContext);

  const [showAlert, setShowAlert] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [transferData, setTransferData] = useState("");

  const [DomainData, setDomainData] = useState([]);

  const [selectedURL, setSelectedURL] = useState("");
  const [text, setText] = useState(""); // State for entered text
  const [selectedDomain, setSelectedDomain] = useState("");
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [message, setMessage] = useState(false);
  // eslint-disable-next-line 
  const [selectAll, setSelectAll] = useState(false);
  const [URLs, setURLs] = useState([]);

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [text]);

  useEffect(() => {
    const DomainFetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/locateDomainURL`);
        setDomainData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    DomainFetchData();
    // eslint-disable-next-line
  }, []);

  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setSelectedDomain(selectedDomain);
  };

  const handleURLChange = (event) => {
    const selectedURL = event.target.value;
    setSelectedURL(selectedURL);
  };

  useEffect(() => {
    const uniqueURLs = Array.from(
      new Set(
        DomainData
          .filter((state) => state.domain === selectedDomain)
          .map((state) => state.cur_url)
          .filter((url) => url && url.trim() !== "")
      )
    ).sort();

    setURLs(uniqueURLs);
  }, [data, selectedDomain, DomainData]);

  const handleSearch = async () => {
    const formData = {
      text: text,
      script: selectedURL,
      domain: selectedDomain,
    };

    setAlertMessage('Search button clicked!');
    setTimeout(() => {
      setAlertMessage('');
    }, 3000);

    try {
      const response = await axios.post(`${API_URL}/LocateSearch`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setData(response.data);
        setFilterData(response.data);
        console.log("value return: ", data)
      } else {
        console.error('Error searching database');
      }
    } catch (error) {
      console.error('Error searching database:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    const currentPageIds = data.map(item => item.id);
    const allSelected = currentPageIds.every(id => selectedIds.includes(id));

    if (allSelected) {
      setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
    } else {
      setSelectedIds([...selectedIds, ...currentPageIds]);
    }
  };

  const MergedHandle = async () => {
    const selectedData = data.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);

    setMessage(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}/mergedAndTransfer`,
        selectedData
      );
      setTransferData(response.data)
      console.log('message mergedAndTransfer', transferData)

      const resetData = data.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      const resetFilterData = filterData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });
      setMessage(false);
      setData(resetData);
      setFilterData(resetFilterData);
      setSelectedIds([]);
      setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  };

  const singleTransfer = async () => {
    const selectedData = data.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}/singleTransfer`,
        selectedData
      );
      setTransferData(response.data)
      console.log('message mergedAndTransfer', transferData)

      const resetData = data.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      const resetFilterData = filterData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      setData(resetData);
      setFilterData(resetFilterData);
      setSelectedIds([]);
      setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {alertMessage && (
      <Popup setOpenModal={setAlertMessage} message="Search button clicked....." />)}
     
      <div className=''>
        <div className="container ">
          <div className="row">
            <div className="col-md-3">

              <select className="form-control" value={selectedDomain} onChange={handleDomainChange}>
                <option value="">Select Domain</option>
                {Array.from(new Set(DomainData.map((state) => state.domain)))
                  .filter((domain) => domain && domain.trim() !== "")
                  .sort()
                  .map((domain, index) => (
                    <option key={index} value={domain}>
                      {domain}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-5">
              <select className="form-control" value={selectedURL} onChange={handleURLChange}>
                <option value="">Select URL</option>
                <option value="all">Select All</option> {/* Add this option */}
                {URLs.map((url, index) => (
                  <option key={index} value={url}>
                    {url}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="container mt-2">
          <div className="row">
            <div className="col-md-8">
              <textarea
                ref={textAreaRef}
                type="text"
                className="form-control"
                placeholder="Enter domain"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div className="col-md-1 mt-2">
              <button className="btn btn-light border" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        
        {message && <div className="p-3 mb-2 bg-alert-success text-white" role="alert">Data processing, please wait...</div>}
        {showAlert && (
          <pre className="p-3 mb-2 bg-alert-success text-white" role="alert">
            {transferData.message.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </pre>
        )}
      </div>

      <div className="text-end me-5">
        {selectedIds.length > 0 && (
          <div className="d-flex justify-content-end">
            <button className="btn btn-light border" onClick={MergedHandle}>Merged Transfer</button>
            <button className="btn btn-light border" onClick={singleTransfer}>Single Transfer</button>
            <button className="btn btn-light border" onClick={() => setSelectedIds([])}>Clear</button>
          </div>
        )}
      </div>

      {Array.isArray(data) ? (
        <div className="container-fluid mb-2">
          <LocateFetchTable
            data={data}
            handleCheckboxChange={handleCheckboxChange}
            setFilterData={setFilterData}
            filterData={filterData}
            setData={setData}
            selectedIds={selectedIds}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
        </div>)
        : (
          <div className='container mb-2 mt-2 text-center'>
            <table id="" className="table table-bordered border" style={{ color: "#222" }}>
              <tbody>
                <tr>
                  <td>No results to display</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
    </>
  );
};

export default Locate;

import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalContext';
//import Popup from '../popup/Popup';
// import LocateMergeTranslationTBL from './LocateMergeTranslationTBL';
import LocatMergeTranslationUpdate from './LocatMergeTranslationUpdate';


const Locate = () => {
  const { API_URL, showToast } = useContext(GlobalContext);
  const [DomainData, setDomainData] = useState([]);
  const [selectedURL, setSelectedURL] = useState("");
  const [text, setText] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [data, setData] = useState([]);
  const [URLs, setURLs] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  // eslint-disable-next-line
  const [selectAll, setSelectAll] = useState(false);
  //const [transferData, setTransferData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [joinedText, setJoinedText] = useState("");
  const textAreaRef = useRef(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [text]);


  const fetchDomainData = async () => {

    if (window.location.pathname === '/locate-merge') {
      try {
        const response = await axios.get(`${API_URL}/collectionsDomainURL`);

        setDomainData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    fetchDomainData();
    // eslint-disable-next-line
  }, []);


  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setSelectedDomain(selectedDomain);
  };

  const handleURLChange = (event) => {
    const selectedURL = event.target.value;
    setSelectedURL(selectedURL);
  };

  useEffect(() => {
    const uniqueURLs = Array.from(
      new Set(
        DomainData
          .filter((state) => state.domain === selectedDomain)
          .map((state) => state.cur_url)
          .filter((url) => url && url.trim() !== "")
      )
    ).sort();

    setURLs(uniqueURLs);
  }, [data, selectedDomain, DomainData]);

  const handleLocateMergeSearch = async () => {

    setData([])
    setSelectAll(false)
    setSelectedIds([])
    const formData = {
      ids: text,
      script: selectedURL,
      domain: selectedDomain,
    };

    showToast('pending', 'Processing, Please wait...', 'top', 10);

    try {
      const response = await axios.post(`${API_URL}/LocateMergeSearch`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.length > 0 && response.status === 200) {
        showToast('success', 'Please wait, propcessing...', 'top', 10);
        setData(response.data);
      } else if (response.data.length === 0) {
        showToast('success', 'Data Not Found...', 'top', 10);
        console.error('Error searching database');
      }
    } catch (error) {
      console.error('Error searching database:', error);
    }
  };

  const handleSelectAll = () => {
    const currentPageIds = data.map(item => item.id);
    const allSelected = currentPageIds.every(id => selectedIds.includes(id));

    if (allSelected) {
      setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
    } else {
      setSelectedIds([...selectedIds, ...currentPageIds]);
    }
  };


  // eslint-disable-next-line
  const handleRemoveText = (indexToRemove) => {
    setSelectedIds((prevSelectedIds) => {
      const updatedSelectedIds = prevSelectedIds.filter((_, index) => index !== indexToRemove);
      return updatedSelectedIds;
    });
  };

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const MergedHandle = async () => {
    const selectedData = data.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);

    setIsLoading(true);

    try {
      // Call the backend API
      const response = await axios.post(`${API_URL}/LocateMergeParentMD5`, selectedData);

      if (response.data.message && response.status === 200) {
        // Display success message
        showToast('success', response.data.message, 'top', 10);
        await handleLocateMergeSearch();
        // Get updated data from the API response
        setSelectedIds([]);
      } else {
        showToast('error', 'Update failed. Please try again.', 'top', 10);
      }
    } catch (error) {
      console.error("Error in MergedHandle:", error);
      showToast('error', 'An error occurred. Please try again.', 'top', 10);
    } finally {
      setIsLoading(false);
    }
  };



  const SetParentMD5Handle = async () => {
    const selectedData = data.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);
    setIsLoading(true)
    showToast('pending', 'Processing, Please wait...', 'top', 10);

    try {
      const response = await axios.post(`${API_URL}/markParentMD5`, selectedData);

      // Log success message from API
      console.log("API Response:", response.data.message);

      if (response.data.message && response.status === 200) {
        showToast('success', response.data.message, 'top', 10);
        await handleLocateMergeSearch();
        setSelectedIds([]);
      } else {
        showToast('error', 'Update failed. Please try again.', 'top', 10);
      }

    } catch (error) {
      console.error("Error updating data:", error);
      alert("Failed to update data. Please try again.");
    } finally { setIsLoading(false) }
  };

  useEffect(() => {
    const selectedTexts = data
      .filter((item) => selectedIds.includes(item.id)) // Filter selected items
      .sort((a, b) => {
        const partA = a.part || "";
        const partB = b.part || "";
  
        if (partA === partB) {
          return (a.seq || 0) - (b.seq || 0);
        }
        return String(partA).localeCompare(String(partB));
      })
      .map((item) => item.cleantxt || ""); 

    setJoinedText(selectedTexts);
  }, [selectedIds, data]);
  


  // // eslint-disable-next-line
  // const toggleSelection = (id) => {
  //   setSelectedIds((prev) =>
  //     prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
  //   );
  // };

  return (
    <>
      <div className=''>
        <div className="container ">
          <div className="row">
            <div className="col-md-3">

              <select className="form-control" value={selectedDomain} onChange={handleDomainChange}>
                <option value="">Select Domain</option>
                {Array.from(new Set(DomainData.map((state) => state.domain)))
                  .filter((domain) => domain && domain.trim() !== "")
                  .sort()
                  .map((domain, index) => (
                    <option key={index} value={domain}>
                      {domain}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-5">
              <select className="form-control" value={selectedURL} onChange={handleURLChange}>
                <option value="">Select URL</option>
                <option value="all">Select All</option> {/* Add this option */}
                {URLs.map((url, index) => (
                  <option key={index} value={url}>
                    {url}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="container mt-2">
          <div className="row">
            <div className="col-md-8">
              <textarea
                ref={textAreaRef}
                type="text"
                className="form-control"
                placeholder="Enter IDs ( ex. 220, 221, 222 or 220-222 )..."
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div className="col-md-1 mt-2">
              <button className="btn btn-light border" onClick={handleLocateMergeSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
     
        {selectedIds.length > 0 ? (
          <div className="container mb-2 mt-3 border shadow-lg py-3 rounded">
            {joinedText.map((sentence, index) => (
              <span
                className="ps-0 py-1 fs-3 mt-1 mb-2"
                key={index}
                style={{
                  backgroundColor: index % 2 === 1 ? "pink" : "#89CFF0",
                }}
              >
                {(sentence || "").trim()}
              </span>
            ))}
          </div>
        ) : (
          ""
        )}
   

      {/* {selectedIds.length > 0 ? <div className="container mb-2 mt-3 border shadow-lg p-3 bg-pink fs-5 rounded">{joinedText || "None"} </div> : ""} */}

      <div className="container ms-3 mb-2 mt-2">
        <div className="d-flex justify-content-end">
          <div className="d-flex justify-content-end">
            {selectedIds.length > 1 && (
              <button className="btn btn-primary border" onClick={MergedHandle}>
                {isLoading ? ("Processing, Please wait...") : ('Merge Selected MD5')}
              </button>
            )}
            {selectedIds.length === 1 && (
              <button className="btn btn-primary border" onClick={SetParentMD5Handle}>
                {isLoading ? ("Processing, Please wait...") : ('Mark Parent MD5')}
              </button>
            )}
          </div>
        </div>
      </div>

      {data.length > 0 ?
        <div className="container-fluid mb-2">
          <LocatMergeTranslationUpdate
            currentPosts={data}
            setFilterData={setData}
            filterData={data}
            setData={setData}
            handleCheckboxChange={handleCheckboxChange}
            selectedIds={selectedIds}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
        </div>
        :
        <div className='container mb-2 mt-2 text-center'>
          <table id="" className="table table-bordered border" style={{ color: "#222" }}>
            <tbody>
              <tr>
                <td className='fs-1 fw-lighter'>No results to display</td>
              </tr>
            </tbody>
          </table>
        </div>
      }



    </>
  );
};

export default Locate;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Title = () => {
  const location = useLocation();

  const routeTitles = {
    '/': 'Collections',
    '/contents': 'Contents',
    '/translation': 'Translation',
    '/segregate': 'Segregate - Split & Merge',
    '/locate': 'Locate',
    '/locate-merge': 'Locate & Merge',
    '/search': 'Search'

  };

  const currentRoute = location.pathname;
  const defaultTitle = routeTitles[currentRoute] || 'Unknown Title';

  const [title, setTitle] = useState(localStorage.getItem('pageTitle') || defaultTitle);

  const handleLinkClick = () => {
    localStorage.removeItem('pageTitle'); // Clear stored title
  };

  useEffect(() => {
    const pageTitle = routeTitles[currentRoute] || 'Unknown Title';
    localStorage.setItem('pageTitle', pageTitle);
    setTitle(pageTitle);
  // eslint-disable-next-line
  }, [currentRoute]);

  return (
    <h4>
      <Link
        className="nav-link text-center text-white py-2 mb-1"
        style={{ backgroundColor: "#007bff" }}
        to={currentRoute}
        onClick={handleLinkClick}
      >
        {title}
      </Link>
      
    </h4>
  );
};

export default Title;

import LocateMergeTable from './LocateMergeTable'

const LocateMerge = () => {


  return (
    <>
      <LocateMergeTable />
      </>
  )
}

export default LocateMerge
